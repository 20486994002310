import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import analyzeIcon from '../../Assets/Images/analyze.svg';
import alignIcon from '../.././Assets/Images/align.svg'
import advanceIcon from '../../Assets/Images/advance.svg';
import Testimonial from '../Testimonial/Testimonial.jsx';
import ShareIcon from "../../Assets/Images/share-icon.png"

const InviteInfo = (props) => {

  const { id } = useParams();


  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite other organizations to take this assessment</h3>
        <p>Help safeguard other organizations from cybercrime by inviting <br /> them to participate in this free assessment.</p>

        {/* <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Understand different perspectives from your team
          </div>

          <div className="reason">
            <img src={alignIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Begin to align around a strategy for growth as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart the best course forward for your church
          </div>

        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>SEND INVITE</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}


      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}

    </div>
  );
}

export default InviteInfo;
