import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, LinearProgress } from "@mui/material";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { sectionsText } from "../globalFunctions";
import upArrow from "../Assets/Images/up-arrow.png";
import prevImg from "../Assets/Images/prev-img.png";
import nextImg from "../Assets/Images/next-img.png";

const ClickableCircularProgressBars = (props) => {

  const { windowWidth } = useWindowDimensions();
  const [sectionsAndSubScores, setSectionsAndSubScores] = useState(props?.sectionsAndSubScores);
  const [activeSection, setActiveSection] = useState(props.sortedSectionsAndSubScores[0]?.section);
  const [progressBarSize, setProgressBarSize] = useState(136);

  
  // use this prop to show/hide TextBox component with text from globalFunctions sectionTexts
  // let showTextBox = true;
  let showTextBox = false;
  if (props.showTextBox === false) showTextBox = false;
  
  
  useEffect(() => {
    setActiveSection(props.sortedSectionsAndSubScores[0]?.section);
  }, [props.sortedSectionsAndSubScores]);

  useEffect(() => {
    setSectionsAndSubScores(props?.sectionsAndSubScores?.map((obj, idx) => {
      if (obj.section === props.sortedSectionsAndSubScores[0]?.section) {
        return { ...obj, clicked: true };
      }
      return { ...obj, clicked: false };
    }));
  }, [props?.sectionsAndSubScores, props.sortedSectionsAndSubScores]);

  useEffect(() => {
    if (windowWidth <= 830) setProgressBarSize(136);
    else if (windowWidth > 830 && windowWidth < 1100) setProgressBarSize(110);
    else setProgressBarSize(136);
  }, [windowWidth]);


  useEffect(() => {
    setSectionsAndSubScores((prev) => {
      return prev.map((obj) => {
        if (obj.section === activeSection) {
          return { ...obj, clicked: true };
        }
        return { ...obj, clicked: false };
      });
    });
  }, [activeSection]);

  const handleSectionClick = (section) => {
    
    setActiveSection(section);

    setSectionsAndSubScores((prev) => {
      return prev.map((obj) => {
        if (obj.section === section) {
          return { ...obj, clicked: true };
        }
        return { ...obj, clicked: false };
      });
    });

  };

  const decideColor = (section) => {
    const lowScoreThreshold = 80;
    const lowestScore = props.sortedSectionsAndSubScores[0]?.score;
    const lowestScoreSection = props.sortedSectionsAndSubScores[0]?.section;
    const highestScore = props.sortedSectionsAndSubScores[props.sortedSectionsAndSubScores.length - 1]?.section;
    const lastTwoElements = props.sortedSectionsAndSubScores.slice(-2);
    const isHighestScoreUnique = lastTwoElements?.[0]?.score !== lastTwoElements?.[1]?.score;
    let customColor = "#100F2E";
    // if ((section === lowestScoreSection) && (lowestScore < lowScoreThreshold)) customColor = "#D23C3C"; // red for lowest score
    if ((section === lowestScoreSection) && (lowestScore < lowScoreThreshold)) customColor = "#100F2E";
    else if ((section === highestScore) && isHighestScoreUnique && (highestScore >= lowScoreThreshold)) customColor = "#100F2E";
    return customColor;
  }


  return (
    <>
    <div className="score-section-container">
      {sectionsAndSubScores?.map((sec, idx) => {

        const customColor = decideColor(sec.section);
        
        return (
          <div key={sec.section} className="score-section" onClick={() => handleSectionClick(sec.section)}>
            {/* <p className="score-txt">{Math.round(sec.score)}%</p> */}
            <div className="score-slider">
              {/* <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} /> */}
              <IndividualCircularProgressBar size={progressBarSize} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} />
            </div>
            
            <div className="score-name-container" style={{ position: "relative" }}>
              <p className="score-name" style={{ borderBottom: (sec.clicked && windowWidth > 830 && showTextBox) ? "4px solid #796BE1" : "4px solid transparent", color: (sec.clicked && showTextBox) ? "#796BE1" : "#343333", fontWeight: (showTextBox ? sec.clicked ? "700" : "700" : "700") }}>{sec.section}</p>
              {(sec.clicked && windowWidth > 830 && showTextBox) && <img src={upArrow} alt="up arrow to show selected item" style={{ position: "absolute", bottom: "1px", left: "50%", width: "18px", height: "15px", transform: "translate(-50%, 0%)" }} />}
            </div>

          </div>)
      })}
    </div>
    
    {/* {showTextBox ? <TextBox activeSection={activeSection} setActiveSection={setActiveSection} sectionsAndSubScores={sectionsAndSubScores} /> : <div style={{ height: "30px" }}></div>} */}

    </>
  );

}



export default ClickableCircularProgressBars;


const TextBox = (props) => {

  const { windowWidth } = useWindowDimensions();
  const activeSection = props.activeSection?.toString()?.toLowerCase();
  const activeSectionText = sectionsText[activeSection];


  const sectionsAndSubScores = props.sectionsAndSubScores;

  const switchToPrevSection = () => {
    const sectionIndex = sectionsAndSubScores?.findIndex((obj) => obj.section?.toLowerCase() === activeSection?.toLowerCase());
    if (sectionIndex <= 0) {
      props.setActiveSection(sectionsAndSubScores?.[sectionsAndSubScores?.length - 1]?.section);
    } else {
      props.setActiveSection(sectionsAndSubScores?.[sectionIndex - 1]?.section);
    }
  }

  const switchToNextSection = () => {
    const sectionIndex = sectionsAndSubScores?.findIndex((obj) => obj.section?.toLowerCase() === activeSection?.toLowerCase());
    if (sectionIndex >= sectionsAndSubScores?.length - 1) {
      props.setActiveSection(sectionsAndSubScores?.[0]?.section);
    } else {
      props.setActiveSection(sectionsAndSubScores?.[sectionIndex + 1]?.section);
    }
  }
  
  return (
    <>
    {windowWidth <= 830 && (
    <>
    <h3 style={{ textAlign: "center", maxWidth: windowWidth <= 550 ? "75%" : "100%", margin: "30px auto", fontWeight: 600 }}>Click on the arrows to unlock specific recommendations.</h3>
    <div className="sections-switcher">
      <Button onClick={switchToPrevSection} className="switch-btn switch-prev"><img src={prevImg} alt="go to previous section" /></Button>
      <h2>{activeSection}</h2>
      <Button onClick={switchToNextSection} className="switch-btn switch-next"><img src={nextImg} alt="go to next section" /></Button>
    </div>
    </>)}

    <div className="section-text-container">
      {<p className="header">{activeSectionText.recommendation.header}</p>}
      
      {activeSectionText.recommendation.text?.map((item, idx) => {
        return <p className="text" key={idx}>{item}</p>
      })}

      {<p className="header">{activeSectionText.service.header}</p>}
      
      {activeSectionText.service.text?.map((item, idx) => {
        return <p className="text" key={idx}>{item}</p>
      })}

    </div>
    </>
  );

}

const IndividualCircularProgressBar = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate"
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" {...props}
        value={-props.value} // to show anticlockwise progress 
        sx={{
          '&': { color: `${props.customcolor} !important`, zIndex: 1, },
          '&.MuiCircularProgress-root': { strokeLinecap: 'round', },
        }} />
      <Box sx={{
        top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}


