import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../.././Assets/Images/Matt-Wilson-testimonial.png';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../.././Assets/Images/leftQuote.png';
import bottomQ from '../.././Assets/Images/rightQuote.png';
import quotation from "../.././Assets/Images/quote-single.png";

const Testimonial = () => {

  const { id } = useParams();

  return (
    <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>It’s very hard to be up to date and up to speed on every section of the network, whether it's the servers, the switches, or the firewall. You can know a lot about all those areas, but with a managed service provider (like <span>Higher Ground</span> ), you have people who are extremely knowledgeable about every area of the network instead of just one..</p>
            
            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{transform:'rotate(180deg)'}} alt="quote icon" /></div></div>
            
          </div>
        <div>
          {/* <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={125} height={125} />
          </div> */}
        </div>
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          Curtis Ponzio<p className="designation-inner">IT Consultant, Southeast Texas</p> 
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}
        </div>
      </div>
  );
}

export default Testimonial;
