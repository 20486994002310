import q1img from './Assets/Images/q1.png';
import q2img from './Assets/Images/q2.png';
import q4img from './Assets/Images/q4.png';
import q6img from './Assets/Images/q6.png';
import q7img from './Assets/Images/q7.png';
import q9img from './Assets/Images/q9.png';
import q10img from './Assets/Images/q10.png';
import q11img from './Assets/Images/q11.png';
import q12img from './Assets/Images/q12.png';
import q15img from './Assets/Images/q15.png';
import q16img from './Assets/Images/q16.png';
import q18img from './Assets/Images/q18.png';
import q20img from './Assets/Images/q20.png';
import q21img from './Assets/Images/q21.png';
import q24img from './Assets/Images/q24.png';
import { height, maxHeight, maxWidth } from '@mui/system';

export const questions_statistics = [
  {
    id: 1,
    title: <><b>57%</b> of the surveyed organizations said they have information security policies and procedures in place.</>, 
    desc: "Well-defined policies and procedures inform your staff how to protect your organization’s data so they know what is expected and what is not allowed.",
    image: { 
      src: q1img, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "80px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 2,
    title: <>At least <b>7/10</b> of the surveyed organizations were found <b>vulnerable to risks of critical financial loss, reputation damage, and regulatory penalties</b> because they do not update their information security policies and processes annually.</>,
    desc: "As systems and technologies evolve, they may become susceptible to vulnerabilities. It’s critical to ensure that policies and procedures are still valid. Reviewing your systems and the procedures together at least annually is a best practice to ensure you have enforceable policies.",
    image: { 
      src: q2img, 
      desktop: { height: "45px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "40px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 3,
    title: "",
    desc: "The top barriers to hiring full- or part-time IT staff are cost and not knowing who to hire. If either of these are hurdles for your organization, a managed services provider might be the solution. It’s essential to have reliable IT professionals to patch your software or update your hardware when needed.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 4,
    title: <><b>73%</b> of the surveyed organizations said they do not have an incident response plan or incident response team in place.</>,
    desc: "Without an IRP that gives step-by-step instructions on how to proceed and whom to contact in case of a security breach or cyberattack, your organization is likely to suffer severe damage. An IRP should be reviewed and updated annually to reflect changes, such as new team members and updated systems.",
    image: { 
      src: q4img, 
      desktop: { height: "115px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 5,
    title: "",
    desc: "The best way to reduce risk from insecure applications on your devices and network is to start with a secure baseline device setup and then track, control, and audit changes.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 6,
    title: <><b>A staggering 84%</b> of the surveyed organizations were found exposed to the risk of cyberattacks due to a lack of critical information security training.</>,
    desc: "As attack methods become more sophisticated, training staff and volunteers every year on current social engineering tactics helps to protect your data and systems. It’s also important to train all new staff and volunteers prior to giving them access to your systems.",
    image: { 
      src: q6img, 
      desktop: { height: "60px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "55px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 7,
    title: <>As many as <b>62%</b> of the surveyed organizations said they are intentional about maintaining an inventory of the hardware connected to their network.</>,
    desc: "Maintaining an inventory of these devices is the first step toward understanding possible threats your organization may face. Keep track of all hardware devices on your network to ensure that only authorized devices can access your information.",
    image: { 
      src: q7img, 
      desktop: { height: "35px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 8,
    title: "",
    desc: "Thank you! This helps us understand the complexity of your organization’s information security program.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 9,
    title: <><b>75%</b> of the surveyed organizations never had an independent third-party conduct vulnerability and/or network penetration scanning.</>,
    desc: "Penetration testing is a great way to assess your network’s security posture. By conducting internal and external scans, you can identify vulnerabilities and real-world threats before hackers exploit them.",
    image: { 
      src: q9img, 
      desktop: { height: "115px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 10,
    title: <>An <b>astounding 8 out of 10</b> of the surveyed organizations were <b>found vulnerable to critical data loss.</b></>,
    desc: "Backups protect against human error, hardware failure, virus attacks, power failures, and natural disasters. Backups can also save time and money when these failures occur. It's also essential to test backups and restore procedures to ensure they work! Backup archives could be corrupt or inconsistent, preventing proper restoration.",
    image: { 
      src: q10img, 
      desktop: { height: "60px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "60px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 11,
    title: <>An <b>alarming 80%</b> of cyberattacks could have been avoided with a scheduled patch management program.</>, 
    desc: "Having a scheduled patch management program to fix vulnerabilities in software and applications helps your organization reduce its security risk.", 
    image: { 
      src: q11img, 
      desktop: { height: "120px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "120px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 12,
    title: <><b>9/10</b> of the surveyed organizations said they ensure that all laptops and mobile devices with access to their organizations systems are encrypted or password protected.</>,
    desc: "You should enable the password, fingerprint, and/or facial recognition features to protect your devices. If a device is ever stolen, its hard drive and files can simply be removed. To prevent data loss, it’s best practice to encrypt your data at rest.", 
    image: { 
      src: q12img, 
      desktop: { height: "35px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 13,
    title: "",
    desc: "Make sure users only have the access they need. This is the principle of least privilege. For example, administrators should only use the administrative account for those privileged functions and the non-administrative one for routine, daily activities. This reduces your organization’s overall risk of system-wide damage when an account is compromised.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 14,
    title: "",
    desc: "Requiring unique logins for each user is one of the best ways to protect sensitive information from unauthorized users.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 15,
    title: <>A concerning <b>84%</b> of the surveyed organizations were found to be <b>vulnerable to cyberattacks</b> due to weak passwords.</>,
    desc: "A long and unique password is more difficult to guess and can be changed less frequently.",
    image: { 
      src: q15img, 
      desktop: { height: "35px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 16,
    title: <><b>More than half</b> of the surveyed organizations said they ensure only authorized devices can access their networks.</>,
    desc: "A great understanding of who has access to your networks allows you to segregate unauthorized or unmanaged assets to a separate network, which also protects your authorized devices.",
    image: { 
      src: q16img, 
      desktop: { height: "115px", width: "auto" }, 
      mobile: { height: "auto", maxHeight: "105px", width: "auto", maxWidth: "100%" }
    }
  },
  {
    id: 17,
    title: "",
    desc: "Not actively managing (e.g., keeping inventory and restricting) the operating systems and applications on your network makes it more difficult to protect your systems. Without a list of authorized and unauthorized software, the vulnerabilities associated with them are unknown. This increases your organization’s risk significantly.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 18,
    title: <><b>94%</b> of administrators use MFA.</>,
    desc: "A two-step authentication for users organization-wide increases your account security.",
    image: { 
      src: q18img, 
      desktop: { height: "45px", width: "auto" }, 
      mobile: { height: "45px", width: "auto" }
    },
    position: "top"
  },
  {
    id: 19,
    title: "",
    desc: "Other users on the same network have the ability to intercept information transported to and from the internet via HTTP sites. The \"S\" at the end of HTTPS represents an additional layer of security that encrypts all data passed between your visitor's web browser and the web hosting server, including passwords and payment information.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 20,
    title: <>About <b>8 out of 10</b> of the surveyed organizations said their systems are configured with antivirus and firewall protections.</>,
    desc: "Antivirus and firewall protections are considered the baseline of protection.", 
    image: { 
      src: q20img, 
      desktop: { height: "45px", width: "auto" }, 
      mobile: { height: "30px", width: "auto" }
    }
  },
  {
    id: 21,
    title: <>In 2023, <b>3 out of 4</b> companies were <b>at risk of cyberattacks</b>.</>,
    desc:  "Cyberattacks, if successful, might have serious consequences, two of the main ones being reputational and financial damage.", 
    image: { 
      src: q21img, 
      desktop: { height: "60px", width: "auto" }, 
      mobile: { height: "60px", width: "auto" }
    }
  },
  {
    id: 22,
    title: "",
    desc: "", 
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 23,
    title: "",
    desc: "", 
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 24,
    title: "",
    desc: "Emergency patching is needed from time to time to secure an actively exploited vulnerability. In the case of a zero-day exploit, if you don't have procedures in place for emergency patching, the bad guys could infect your systems before your normal patching schedule comes around.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
  {
    id: 25,
    title: <><b>73%</b> of the surveyed organizations said they have security cameras monitoring their premises.</>, desc: "Security cameras are an excellent deterrent to criminals and can help prevent false accusations and claims against the organization and its staff.",
    image: { 
      src: q24img, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "85px", width: "auto" }
    }
  },
  {
    id: 26,
    title: "",
    desc: "Background checks help you make educated hiring decisions, especially when your hires might work with at-risk people. Best practices for background checks indicate that organizations should screen staff/volunteers upon hire and re-screen as often as every two years.",
    image: { 
      src: null, 
      desktop: { height: "80px", width: "auto" }, 
      mobile: { height: "80px", width: "auto" }
    }
  },
];
